




































import { Component } from 'vue-property-decorator';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import Util from '@/utils/Util';

import RowRating from '@/components/portfolio/RowRating.vue';
import RowChartBar from '@/components/portfolio/RowChartBar.vue';
import TableClassificationCategory from '@/components/portfolio/TableClassificationCategory.vue';
import RowChartSubCategory from '@/components/portfolio/RowChartSubCategory.vue';
import TableCategoryAgent from '@/components/portfolio/TableCategoryAgent.vue';
import TableAgentClassification from '@/components/portfolio/TableAgentClassification.vue';
import FilterBar from '@/components/main/FilterBar.vue';
import { internet } from '@/utils/Internet';
import MixinMain from '@/mixing/MixinMain.vue';
@Component({
	name: 'MainPortFolio',
	components: {
		AppContainerBase,
		RowRating,
		RowChartBar,
		TableClassificationCategory,
		RowChartSubCategory,
		TableCategoryAgent,
		TableAgentClassification,
		FilterBar,
	},
})
export default class MainPortFolio extends MixinMain {
	public title = '';
	public chart_tipo_llamada = '';
	public chart_changed = 0;
	public backToText = this.$t("MainPortfolio.textBackTo");

	mounted() {
		const params: string = this.$route.params.portfolio;
		this.title = Util.replaceTextWith(
			this.$t("MainPortfolio.title") as string,
			'%s',
			params
		);
		const request_tipLlam = internet
			.newRequest()
			.get(
				`vue-xentric/tipLlam?FiltrAgent=AllAgents&${this.getFilterUrl}`
			);
		Util.waitForPromises([
			request_tipLlam,
		])
			.then((responses) => {
				this.chart_tipo_llamada = responses[0].data.tipo_llamada[0];
				this.chart_changed += 1;
			})
	}

	public changeTipoLlam(tipo_llamada:string) {
		console.log("CAMBIANDO A ",tipo_llamada);
		let nuevo_tipo_llamada = tipo_llamada.replaceAll(' ', '_');
		this.chart_tipo_llamada = nuevo_tipo_llamada;
		this.chart_changed += 1;
	}

	public back(){
		this.$router.push({
			name : 'Menu',
		});
	}
}
