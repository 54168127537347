















import { Component, Prop } from 'vue-property-decorator';
import MixinMain from '@/mixing/MixinMain.vue';
import BarChart from '@/components/util/BarChart.vue';

import { ChartData } from 'chart.js';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { AxiosResponse } from 'axios';
import LineDialog from '@/components/portfolio/LineDialog.vue';
import { ISubCat } from '@/model/portfolio/ISubCat';
@Component({
	name: 'RowChartSubCategory',
	components: {
		BarChart,
		LineDialog,
	},
})
export default class RowChartSubCategory extends MixinMain {
	@Prop({
		type: String,
		required: true,
	})
	readonly tipo_llamada!: string;
	public title = Util.replaceTextWith(
			this.$t("MainPortfolio.titleChart") as string,
			'%s',
			this.tipo_llamada.replaceAll('_', ' ').toUpperCase()
		);
	public subTitle = this.$t("MainPortfolio.subTitleChart");
	public chartEvAudiosTip: ChartData = {
		datasets: [],
	};
	public extraData: Array<number> = [];
	public isLoading = false;
	mounted() {
		this.getAllData();
	}

	public getAllData() {
		console.log("se ocupa un getAllData()")
		this.isLoading = true;
		const request_evAudio = internet
			.newRequest()
			.get(
				`vue-xentric/res-sub-cat-tipLlam?${this.getFilterUrl}`
			);
		const request_evAudioTips = internet
			.newRequest()
			.get(
				`vue-xentric/evAudiosTipoLlamCamp?${this.getFilterUrl}`
			);
		Util.waitForPromises([
			request_evAudio,
			request_evAudioTips,
		])
			.then((responses) => {
				this.evaluateEvAudiosTipLlam(
					responses[0] as AxiosResponse
				);
				this.evaluateExtraData(
					responses[0] as AxiosResponse
				);

				this.subTitle = Util.replaceTextWith(
					this.subTitle as string,
					'%s',
					responses[1].data.filter((el:any) => el.tipo_llamada === this.tipo_llamada).length
				);
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}
	private evaluateExtraData(response: AxiosResponse) {
		//console.log(this.tipo_llamada);
		const arrayOfIEvTip: Array<ISubCat> = response.data.filter((el:ISubCat) => el.tipoLlam === this.tipo_llamada);
		for (let evTipLlam of arrayOfIEvTip) {
			this.extraData.push(evTipLlam.numeroEv);
		}
	}
	private evaluateEvAudiosTipLlam(response: AxiosResponse) {
		const subCate: Array<ISubCat> = response.data.filter((el:ISubCat) => el.tipoLlam === this.tipo_llamada);
		console.table(subCate);

		this.$set(
			this.chartEvAudiosTip,
			'labels',
			subCate.map((ele: ISubCat): string => {
				return ele.subcategoria;
			})
		);
		this.chartEvAudiosTip.datasets = [
			{
				data: subCate.map((ele: ISubCat): number => {
					return ele.puntajeResultado;
				}),
			},
		];
	}
}
